<template>
  <div class="app-container">
    <el-row>
      <div>{{ $t('page.Newpurchaseplan') }}</div>
      <el-divider />
    </el-row>
    <el-form ref="form" :model="allPlanform" :rules="rules" label-width="100px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :label="$t('page.Style')">
              <el-select v-model="allPlanform.style" value-key="id" clearable filterable @change="styleSelectChange">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.styleName"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.Color')">
              <el-select v-model="allPlanform.color" clearable filterable>
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.Size')">

              <el-select v-model="allPlanform.sizes" clearable filterable>
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="备货渠道" prop="stockChannelId">
              <el-select v-model="allPlanform.stockChannelId" clearable filterable @change="handlePlatformSite">
                <el-option
                  v-for="item in StockChannelOptions"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主平台/站点">
              <el-select v-model="allPlanform.mainPlatformSite" clearable filterable disabled style="width:100%">
                <el-option
                  v-for="item in StockChannelOptions"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="平台" prop="platformName">
              <el-select v-model="allPlanform.platformName" value-key="platformId" clearable filterable @change="platformchange">
                <el-option
                  v-for="item in alldatas.platformList"
                  :key="item.platformId"
                  :label="item.platformName"
                  :value="item.platformName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="站点" prop="siteCode">
              <el-select v-model="allPlanform.siteCode " value-key="id" clearable filterable>
                <el-option
                  v-for="item in siteArr"
                  :key="item.siteId"
                  :label="item.siteCode"
                  :value="item.siteCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="fnsku/upc" :class="$i18n.locale">
              <el-input v-model="allPlanform.sku" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col> -->
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">清空</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-row style="margin-bottom:20px">
      <el-col :span="20">
        <el-button type="primary" @click="allclick()">一键赋值</el-button>
      </el-col>
    </el-row>
    <el-table
      id="myTable"
      ref="uploadTableDataRef"
      v-loading="TableLoading"
      :row-key="getRowKeys"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa'}"
      height="350px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="100"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
      >
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>

      <el-table-column
        prop="sku"
        label="SKU"
        width="180"
        align="center"
      />
      <el-table-column
        prop="fnsku"
        label="FNSKU"
        align="center"
      />
      <el-table-column
        prop="upc"
        label="UPC"
        align="center"
      />
      <el-table-column
        prop="style,color,size"
        label="规格型号"
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column
        width="200"
        prop="pairs"
        label="计划数量"
        align="center"
      >
        <template slot-scope="scope">
          <!-- @input="getTotalNumber('uploadTableDataRef', scope.row)" -->
          <el-input-number v-model="scope.row.pairs" :precision="0" :min="1" style="text-align:center;width:150px;" :controls="false" @change="getTotalNumber('uploadTableDataRef', scope.row)" />
        </template>
      </el-table-column>
    </el-table>

    <Pagination :pager="pager" end class="mt-1" @pagination="handlePagination" />

    <el-row class="grid-content bg-blue mt-4" type="flex" justify="center">
      <el-button type="primary" :loading="Loading" @click="onSubmit('ruleForm')">确认</el-button>
    </el-row>
  </div>
</template>
<script>
import { addpurchaseconfirm, listSkuInfoByPage, querySizeList, queryColorList, StockChannelAllList } from '@/api/scm-api'
import Pagination from '@/components/Pagination'

import { styleListConfig } from '@/api/configuration'

export default {
  components: { Pagination },
  data() {
    return {
      siteArr: [],
      siteCode: '',
      platformName: '',
      allPlanform: {
        platformId: '',
        platformName: '',
        siteCode: '',
        siteId: '',
        sku: '',
        mainPlatformSite: '',
        stockChannelId: ''
      },
      // pairs: '',
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      platforoptions: [],
      siteoptions: [],
      StockChannelOptions: [],
      TableLoading: false,
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false, // 释放任务
      tableDatas: [],
      sidvoList: [],
      Loading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      rules: {
        platformName: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        siteCode: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        stockChannelId: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      },
      alldatas: [],
      getRowKeys(row) {
        return row.sku
      }

    }
  },
  computed: {
    queryParameter() {
      const flag = Number(this.$route.query.flag)
      const { stockChannelId } = this.allPlanform
      const platformSite = stockChannelId ? this.StockChannelOptions.find(item => item.channelId === stockChannelId) : ''
      const { mainPlatSiteShop } = platformSite
      if (!mainPlatSiteShop) return
      const { platformName, siteName, shopName, platformId, siteId, shopId } = mainPlatSiteShop
      // const { siteCode, platformName } = this.allPlanform , siteId, platformId
      // const siteId = this.siteArr.find(item => item.siteCode === siteCode)?.siteId
      // const platformId = this.alldatas.platformList.find(item => item.platformName === platformName)?.platformId
      return Object.assign(this.allPlanform, this.pager, { flag, platformId, siteId, shopId, platformName, siteName, shopName })
    }
  },
  created() {
    this.$nextTick(() => {
      this.allclick()
      document.querySelector('#myTable').style.height = '351px'
    })
  },
  mounted() {
    this._queryColorList()
    this._querySizeList()
    this._queryStyleList()
    // this._listPlatformAndSite()
    this._StockChannelList()
  },
  methods: {
    handlePagination(val) {
      Object.assign(this.pager, val)
      this._listSkuInfoByPage()
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await styleListConfig()
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.styleName === value)
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // 选择平台带出站点
    // async _listPlatformAndSite() {
    //   const { datas } = await listByPrincipalConfig()
    //   this.alldatas = datas
    //   const obj = this.alldatas.platformList.find(item => item.platformName === 'Amazon') || []
    //   this.allPlanform.platformName = obj?.platformName
    //   const { datas: { siteList }} = await listByPrincipalConfig({ platformId: obj.platformId })
    //   this.siteArr = siteList
    //   this.allPlanform.siteCode = this.siteArr[0].siteCode
    // },
    // // 选中平台的触发（change）事件
    // async platformchange(val) {
    //   const platformId = this.alldatas.platformList.find(item => item.platformName === val)?.platformId
    //   this.allPlanform.siteCode = ''

    //   const { datas: { siteList }} = await listByPrincipalConfig({ platformId })
    //   this.siteArr = siteList
    // },
    // 点击查询获取信息
    queryClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.pager.current = 1
          this._listSkuInfoByPage()
        } else {
          this.$message.warning('校验不通过')
          return false
        }
      })
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.allPlanform.style = ''
      this.allPlanform.color = ''
      this.allPlanform.sizes = ''
      this.allPlanform.stockChannelId = ''
      this.allPlanform.mainPlatformSite = ''
      this.queryClick()
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _listSkuInfoByPage() {
      try {
        this.TableLoading = true
        const { datas } = await listSkuInfoByPage(this.queryParameter)
        this.tableDatas = datas.records
        this.tableDatas.map((e, index) => {
          e.pairs = undefined
          // e.myId = ((datas.pager.current - 1) * datas.pager.size) + index
        })
        Object.assign(this.pager, datas.pager)
      } finally {
        this.TableLoading = false
      }
    },

    // 计划数量
    getTotalNumber(ref, row) {
      let outPairsBoo = true
      if (row.pairs <= 0) outPairsBoo = false
      this.$refs[ref].toggleRowSelection(row, outPairsBoo)
    },
    allclick() {
      const data = this.$refs.uploadTableDataRef.selection
      if (data.length > 0) {
        this.tableDatas = this.tableDatas.map(item => {
          if (data.some(e => e.sku === item.sku)) {
            item.pairs = data[0].pairs
          }
          return { ...item }
        })
      }
    },
    handleSelectionChange(val) {
      this.sidvoList = val
    },
    // 批量新增确认保存
    onSubmit() {
      let vo = {}
      if (!this.sidvoList.length || this.sidvoList.some(item => !item.pairs)) {
        this.$message.warning('请至少勾选一条已填写计划数量的数据')
        return
      }
      // const { siteCode, platformName } = this.allPlanform
      // const siteId = this.siteArr.find(item => item.siteCode === siteCode)?.siteId
      // const platformId = this.alldatas.platformList.find(item => item.platformName === platformName)?.platformId
      const { stockChannelId } = this.allPlanform
      const platformSite = stockChannelId ? this.StockChannelOptions.find(item => item.channelId === stockChannelId) : ''
      const { mainPlatSiteShop } = platformSite
      if (!mainPlatSiteShop) return
      const { platformName, siteName, shopName, platformId, siteId, shopId } = mainPlatSiteShop
      vo = Object.assign({}, { itmes: this.sidvoList }, { stockChannelId, siteName, platformName, siteId, platformId, shopName, shopId })
      this._addpurchaseconfirm(vo)
    },
    async _addpurchaseconfirm(vo) {
      try {
        this.Loading = true
        const { code, msg, datas } = await addpurchaseconfirm(vo)
        datas.map(item => {
          const stockChannelId = this.allPlanform.stockChannelId
          return Object.assign(item, { stockChannelId })
        })
        console.log(datas)
        const andvo = JSON.parse(sessionStorage.getItem('indatas'))
        if (andvo && Array.isArray(andvo)) {
          if (andvo.some(item => this.allPlanform.stockChannelId !== item.stockChannelId)) return this.$message.warning('备货渠道须与当前已经添加的计划明细备货渠道一致')
          datas.map(e => {
            var index = andvo.findIndex((i) => {
              return e.sku === i.sku && e.siteCode === i.siteCode && e.platformName === i.platformName
            })
            if (index === -1) {
              andvo.push(e)
            } else {
              const { expectPairs } = e
              andvo[index].expectPairs = expectPairs
            }
          })
          sessionStorage.setItem('indatas', JSON.stringify(andvo))
        } else {
          sessionStorage.setItem('indatas', JSON.stringify(datas))
        }

        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          const { planForm, disabled, id } = this.$route.query
          this.$router.push({
            path: '../',
            append: true,
            query: { id, disabled, planForm }
          })
          this.Loading = false
        }
      } catch (err) {
        this.$confirm(err, '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {
          this._listSkuInfoByPage()
          this.$refs.uploadTableDataRef.clearSelection()
        })
      } finally {
        this.Loading = false
      }
    },
    // 备货渠道带出主平台站点店铺
    handlePlatformSite(val) {
      const platformSite = this.StockChannelOptions.find(item => item.channelId === val)
      const { mainPlatSiteShop } = platformSite
      if (!mainPlatSiteShop) return
      const { platformName, siteName, shopName } = mainPlatSiteShop
      this.allPlanform.mainPlatformSite = `${platformName}/${siteName}/${shopName}`
    },
    // 备货渠道下拉框
    async _StockChannelList() {
      const { datas } = await StockChannelAllList({ status: 1 })
      this.StockChannelOptions = datas
    }

  }
}
</script>

